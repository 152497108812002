import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Button from './button';

const BlogPostItemContainer = styled.div`
	flex: 0 0 33.33%;
	padding: 1%;
	display: flex;
	@media (max-width: 767px) {
		flex: 0 0 50%;
		padding-bottom: 4%;
	}
	@media (max-width: 585px) {
		flex: 0 0 100%;
	}
	h2 {
		font-size: calc(0.1vw + 0.9em);
		font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		color: black;
		font-weight: 600;
		@media (max-width: 585px) {
			font-size: calc(0.35vw + 1.1em);
		}
	}
	p {
		color: #666;
	}
	.innerpadding {
		padding: 0.8em;
		padding-bottom: 0.7em;
	}
`;
const PublishDate = styled.p`
	margin-bottom: 0em;
	letter-spacing: 0.25px;
	font-size: 0.9em;
`;
const Heading = styled.h2`
	margin: 0;
`;
const Excerpt = styled.p`
	font-size: 0.95em;
`;

const BlogPostItem = ({
	title,
	slug,
	image,
	city,
	zipCode,
	author,
	date,
	category,
	categorySlug,
	page = 'blog',
	excerpt
}) => {
	return (
		<BlogPostItemContainer>
			<Link
				to={`/${page}/${slug}/`}
				className={
					'w-full block shadow-sm  hover:shadow-md   bg-white rounded-t-2xl rounded-b-2xl'
				}
				style={{ backgroundColor: 'white' }}
			>
				<div className="aspect-ratio-box ratio-16-9 rounded-t-2xl">
					<GatsbyImage
						image={getImage(image)}
						className="aspect-ratio-box-inside  "
						alt={title}
						style={{
							maxWidth: '585px',
							maxHeight: '390px',
							width: '100%',
							height: '100%'
						}}
					/>
				</div>
				<div className={'innerpadding'}>
					<Heading>{title}</Heading>
					<PublishDate className="flex items-center">
						<div className="">
							<img
								src="/assets/location-pin.svg"
								className="w-2"
							/>
						</div>
						<div className="ml-2">
							{city}, CA {zipCode}
						</div>
					</PublishDate>
					{excerpt ? <Excerpt>{excerpt}</Excerpt> : null}
				</div>
			</Link>
		</BlogPostItemContainer>
	);
};

export default BlogPostItem;
