import { useEffect, useRef } from 'react';
import { getLocPhysicalAndCity as getValueTrackingParams } from '../helpers/adRefTracking';

export const valueTrackLogic = ({ isCity, isCampaign }) => {
	const { city, campaigns } = getValueTrackingParams();
	return (city && city == isCity) || (campaigns && campaigns == isCampaign);
};

export const useValueTrackLogic = ({
	isCity,
	isCampaign,
	conditions = condition => true,
	beforeHook,
	afterHook
}) => {
	const valueTrackedRef = useRef();
	const valueTrackLogicResult = valueTrackLogic({ isCity, isCampaign });
	useEffect(() => {
		if (valueTrackLogicResult) {
			const { city, isCampaign } = getValueTrackingParams();
			// if (conditions(city || isCampaign)) {
			if (beforeHook) beforeHook(valueTrackedRef);
			if (valueTrackedRef.current) {
				const elementClassName = valueTrackedRef.current.className;
				valueTrackedRef.current.className =
					elementClassName + ' hiddenms';
			}
			if (afterHook) afterHook(valueTrackedRef);
			// }
		}
	}, []);
	return valueTrackedRef;
};
